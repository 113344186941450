<template>
  <div
    class="font-lato text-xxs mt-auto flex justify-between font-normal uppercase leading-3 text-white"
  >
    <span>{{ source }}</span>
    <TooltipWeighted
      v-if="!hiddenWeight"
      :data-testid="testId"
      :is-weighted="isWeight"
      :text-content="isWeight ? 'Weighted' : 'Unweighted'"
      is-footer
    />
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  isWeight: boolean
  sourceData?: {
    quarter: number
    year: string
  }
  hiddenWeight?: boolean
  testId: string
}>()

const source = computed(() => {
  return `Source: Benchmark Mineral Intelligence ${props.sourceData?.year || ''} ${
    props.sourceData?.quarter || ''
  }`
})
</script>
