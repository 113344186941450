<template>
  <div class="relative">
    <button
      id="options-menu"
      ref="button"
      class="text-p-yellow-500 flex h-6 w-6 items-center justify-center rounded-full hover:text-gray-600 focus:text-gray-600 focus:outline-none"
      aria-label="Options"
      aria-haspopup="true"
      aria-expanded="true"
      :data-test="`show-menu-${dataTest}`"
      :class="{ 'bg-grey': isOpenDropdown }"
      @click="isOpenDropdown = !isOpenDropdown"
    >
      <more-vert class="h-6 w-6 pointer-events-none" />
    </button>
    <transition name="fade">
      <div
        v-if="isOpenDropdown"
        v-closable="{
          exclude: ['options-menu'],
          handler: 'closeEvent',
        }"
        class="absolute right-0 z-40 mt-2 origin-top-right rounded-md bg-p-blue-500 text-p-yellow-500 uppercase overflow-hidden shadow-xl"
      >
        <DownloadOption
          v-for="option in options"
          :key="option.id"
          :data-test="option.id"
          :label="option.label"
          :icon="option.icon"
          @download="downloadAction(option.id)"
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import type { DownloadOption } from '~/components/reports/DownloadOptions/definitions'
import MoreVert from '~/assets/icons/more_vert.svg'

interface Props {
  dataTest: string
  options: DownloadOption[]
}

const props = defineProps<Props>()

const isOpenDropdown = ref(false)

const downloadAction = (id) => {
  const option = props.options.find((option) => option.id === id)
  if (!option) return

  isOpenDropdown.value = false
  option.action()
}

const closeEvent = () => {
  if (isOpenDropdown.value) isOpenDropdown.value = false
}

defineExpose({
  closeEvent,
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
</style>
